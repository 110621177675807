import React from "react";
import { Link } from "react-router-dom";
import wheelchair from "../Assets/wheelchair.gif";
import surgery from "../Assets/surgery.gif";
import deliveryTruck from "../Assets/delivery-truck.gif";

function AproposCard(props) {
  let gifSrc = "";

  switch (props.title.toLowerCase()) {
    case "matériels particuliers":
      gifSrc = wheelchair;
      break;
    case "matériels professionnels":
      gifSrc = surgery;
      break;
    case "livraison & installation":
      gifSrc = deliveryTruck;
      break;
    default:
      gifSrc = "";
  }

  return (
    <div className="Apropos-cards">
     <span className="Apropos-card-gif">
      <img className="card-gif" src={gifSrc} alt={props.title} />
      </span>
      <p className="Apropos-card-title">{props.title}</p>
      <p className="Apropos-card-description">{props.description}</p>
      {props.button && (
        <Link to={props.button.to} component={props.button.component}>
          <button className="Apropos-card-button">{props.button.label}</button>
        </Link>
      )}
    </div>
  );
}

export default AproposCard;

