import React from "react";
import Navbar from "../Components/Navbar";
import View from "../Components/View";
import Apropos from "../Components/Apropos";
import Footer from "../Components/Footer";

function Home() {
  return (
    <div className="home-section">
      <Navbar />
      <View />
      <Apropos />
      <Footer />
    </div>
  );
}

export default Home;
