import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark,} from "@fortawesome/free-solid-svg-icons";
import "../Styles/Navbar.css";
import { Link, NavLink } from "react-router-dom";
import myLogo from '../Assets/balagne-medical.png';


function Navbar() {
  const [nav, setNav] = useState(false);

  const openNav = () => {
    setNav(!nav);
  };

  return (
    <div className="navbar-section">
      <div className="logo">  
      <Link to="/">
        <img src={myLogo} alt="Balagne Medical" paddingLeft={0} width={90} /> 
      </Link>
      </div>
      

      {/* Desktop */}
      <ul className="navbar-items">
  <li>
    <NavLink to="/" exact className="navbar-links" activeClassName="active">
      Accueil
    </NavLink>
  </li>
  <li>
    <NavLink to="/particuliers" className="navbar-links" activeClassName="active">
      Particuliers
    </NavLink>
  </li>
  <li>
    <NavLink to="/professionnels" className="navbar-links" activeClassName="active">
      Professionnels
    </NavLink>
  </li>
  <li>
    <NavLink to="/contact" className="navbar-links" activeClassName="active">
      Contact
    </NavLink>
  </li>
</ul>

      {/* Mobile */}
      <div className={`mobile-navbar ${nav ? "open-nav" : ""}`}>
  <div onClick={openNav} className="mobile-navbar-close">
    <FontAwesomeIcon icon={faXmark} className="hamb-icon" />
  </div>

  <ul className="mobile-navbar-links">
    <li>
      <NavLink onClick={openNav} to="/" activeClassName="active">
        Accueil
      </NavLink>
    </li>
    <li>
      <NavLink onClick={openNav} to="/particuliers" activeClassName="active">
        Particuliers
      </NavLink>
    </li>
    <li>
      <NavLink onClick={openNav} to="/professionnels" activeClassName="active">
        Professionnels
      </NavLink>
    </li>
    <li>
      <NavLink onClick={openNav} to="/contact" activeClassName="active">
        Contact
      </NavLink>
    </li>
  </ul>
</div>
{/* Hamburger Icon */}
<div className="mobile-nav">
        <FontAwesomeIcon
          icon={faBars}
          onClick={openNav}
          className="hamb-icon"
        />
      </div>
    </div>
  );
}

export default Navbar;