import React from "react";
import "../Styles/LegalDoc.css";

function LegalDocs() {
  return (
    <div className="legal-section-title">
      <h1 className="legal-siteTitle">Balagne Medical Service</h1>

      <div className="legal-text-content">
        <p className="legal-title">Mentions légales :</p>
        <p className="legal-description">
        Conformément à l’article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique, nous mettons à disposition les informations suivantes concernant le présent site internet.
        </p>

        <p className="legal-title">Propriétaire :</p>
        <p className="legal-description">
        Nom/ Raison sociale: Balagne Medical Services. Adresse: Campu longu, route de Calenzana, 20260, Calvi, Corse, France; Adresse électronique: contact@balagnemedical.com; Téléphone: 04.95.30.78.56; N° SIRET: 48124410100012.
        </p>

        <p className="legal-title">Créateur | Websmaster :</p>
        <p className="legal-description">
        Jean-Joseph Agostini; <a href="https://github.com/Jean-Joooo/" target="_blank" rel="noreferrer"> Jean-Joooo</a>; Adresse: Résidence Côté Mer, 20260, Calvi, Corse, France; Tel: 07.77.73.56.20; SIRET: 80181433600039.

        Hebergeur: Netlify.
        
        Adresse: 325 3RD STREET,SUITE 215, SAN FRANCISCO, CA 94107 (USA).
        </p>

        <p className="legal-title">Propriété intellectuelle :</p>
        <p className="legal-description">
        Le propriétaire du site internet désigné ci-dessus est le propriétaire exclusif des droits de propriété intellectuelle. Toute reproduction totale ou partielle est interdite. Toute exploitation non autorisée du site d’un élément qu’il contient sera considérée comme une contrefaçon et poursuivie, conformément aux articles L.335-2 et suivants du Code de Propriété Intellectuelle.
        </p>

        <p className="legal-title">Responsable de publication :</p>
        <p className="legal-description">
        Jean-Joseph Agostini
        </p>
      </div>

      <div className="legal-footer">
        <p>© 2024 Balagne Medical Service</p>
      </div>
    </div>
  );
}

export default LegalDocs;
