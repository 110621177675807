import React from "react";
import Navbar from "../Components/Navbar";
import Particulier from "../Components/Particulier";
import Footerbis from "../Components/Footerbis";

function Particuliers() {
  return (
    <div className="particulier-section">
        <Navbar />
        <Particulier />
      <Footerbis />
    </div>
  );
}

export default Particuliers;