import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
import location from "../Assets/location.png";
import mobilite from "../Assets/mobilite.png";
import hygiene from "../Assets/hygiene.png";
import confort from "../Assets/confort.png";
import mamanbebe from "../Assets/mamanbebe.png";
import "../Styles/Particulier.css";


function Particulier() {
  return (
    <div className="container" id="particuliers" >
      <div className="row justify-content-center">
        <div className="col-md-8">
          <Carousel fade>
            <Carousel.Item>
              <img
                className="particulier-image"
                src={location}
                alt="location"
              />
              <Carousel.Caption>
                <h3>Location matériel médical</h3>
                <p>Besoin de matériel médical? Les conditions: une ordonnance, une attestation/carte vitale, une mutuelle.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="particulier-image"
                src={mobilite}
                alt="mobilite"
              />
              <Carousel.Caption>
                <h3>Mobilité</h3>
                <p>Fauteuils roulants, scooters électriques, déambulateurs, rollators, cannes, béquilles.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="particulier-image"
                src={hygiene}
                alt="hygiene"
              />
              <Carousel.Caption>
                <h3>Hygiène et Incontinence</h3>
                <p>Crèmes protectrices et apaisantes, lotions lavantes et hydratantes, couches adultes, changes complets, alèses.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="particulier-image"
                src={confort}
                alt="confort"
              />
              <Carousel.Caption>
                <h3>Confort et Bien-être</h3>
                <p>Fauteuil releveur, table de lit, matériel pour la salle de bain.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="particulier-image"
                src={mamanbebe}
                alt="mamanbebe"
              />
              <Carousel.Caption>
                <h3>Pour les mamans et les bébés</h3>
                <p>Tire-laits & balances</p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    </div>
  );
}

export default Particulier;