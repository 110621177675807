import React from "react";
import Navbar from "../Components/Navbar";
import SubmitForm from "../Components/SubmitForm";
import Footer from "../Components/Footer";


function Contact() {
  return (
    <div className="SubmitForm-section">
      <Navbar />
      <SubmitForm />
      <Footer />
    </div>
  );
}

export default Contact;
