import React from "react";
import AproposCard from "./AproposCard";
import store2 from "../Assets/store2.png";
import deliveryTruck from "../Assets/delivery-truck.gif";
import wheelchair from "../Assets/wheelchair.gif";
import surgery from "../Assets/surgery.gif";
import "../Styles/Apropos.css";
import { Link } from "react-router-dom";


function Apropos() {
  return (
    <div className="Apropos-section" id="apropos">
      <div className="Apropos-title-content">
        <h3 className="Apropos-title">
          <span>BMS c'est :</span>
        </h3>
        <p className="Apropos-description">
        Une entreprise capable d'intervenir auprès des particuliers, des professionnels de la santé, des collectivités, des hôpitaux, des unités de soins, et des EPHAD.

        </p>
      </div>
      <div className="text-stats" display="block">
        <div className="text-stats-container">
          <p>15.OOO</p>
          <p>Patients accompagnés</p>
        </div>

        <div className="text-stats-container">
          <p>40</p>
          <p>Professionnels de santé nous font confiance</p>
        </div>

        <div className="text-stats-container">
          <p>19</p>
          <p>Années d'expériences</p>
        </div>
      </div>

      <div className="Apropos-cards-content">
        <AproposCard
          title="Matériels Particuliers"
          description="Location et vente de lits, de fauteuils roulants. Produits d'hygiène et incontinence, nutrition, confort et bien être, produits d'allaitement."
          button={{
            label: 'Particuliers',
            to: '/particuliers',
            component: Link,
          }}
          
          img src={wheelchair} alt="wheelchair"/>

        <AproposCard
          title="Matériels Professionnels"
          description="Désinfection et protection, matériels dédiés aux professionnels de santé, pansements."
          button={{
            label: 'Professionnels',
            to: '/professionnels',
            component: Link,
          }}
          img src={surgery} alt="surgery" />

        <AproposCard
          title="Livraison & Installation"
          description="Une équipe de livraison dédiée pouvant intervenir très rapidement, et mettre en place le matériel directement chez vous."
          img src={deliveryTruck} alt="delivery-truck" />

          </div>
          <div className="Apropos-image-section">
          <img className="Apropos-image2" src={store2} alt="store2" />
        </div>
      </div>
      );
    }
    
    export default Apropos;