import React from "react";
import Navbar from "../Components/Navbar";
import LegalDoc from "../Components/LegalDoc";

function Legal() {
  return (
  <div className="Legal-section">
  <Navbar />
  <LegalDoc />
  </div>
  );
}

export default Legal;
