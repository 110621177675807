import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
import desinfection from "../Assets/desinfection.png";
import instrument from "../Assets/instrument.png";
import pansement from "../Assets/pansement.png";
import "../Styles/Professionnel.css";

  function Professionnel() {
    return (
      <div className="container" id="particuliers" >
        <div className="row justify-content-center">
          <div className="col-md-8">
            <Carousel fade>
              <Carousel.Item>
                <img className="professionnel-image" src={desinfection} alt="desinfection"/>
                <Carousel.Caption>
                  <h3>Désinfection et protection</h3>
                  <p>Lingettes & sprays pour la désinfection des instruments, draps, gants d’examens.</p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img className="professionnel-image" src={instrument} alt="instrument"/>
                <Carousel.Caption>
                  <h3>Instruments & appareils de diagnostic</h3>
                  <p>Ciseaux, pinces, bistouris, kits à usage unique, tensiomètres électroniques, otoscopes.</p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img className="professionnel-image" src={pansement} alt="pansement"/>
                <Carousel.Caption>
                  <h3>Pansements</h3>
                  <p>Pour le soin des blessures, nous vous proposons une large gamme de désinfectants, et de pansement adaptés.</p>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>
    );
  }
  
  export default Professionnel;