import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/SubmitForm.css";

const SubmitForm = () => {
  const [isSuccess, setIsSuccess] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const myForm = event.target;
    const formData = new FormData(myForm);

    try {
      const response = await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData).toString(),
      });

      if (!response.ok) {
        throw new Error("Message error");
      }

      setIsSuccess(true);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <section className="contact-form-wrap section">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="section-title text-center">
              <h5 className="text-md mb-2">Message</h5>
              <div className="divider mx-auto my-4"></div>
            </div>
            <form
              id="contact"
              className="contact"
              method="post"
              action="/"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleSubmit}
            >
              <input type="hidden" name="form-name" value="contact" />

              <div className="row">
                <div className="col-12">
                  {isSuccess && (
                    <div className="alert alert-success contact__msg" role="alert">
                      Votre message a bien été envoyé.
                    </div>
                  )}
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <input name="nom" id="nom" type="text" className="form-control" placeholder="Nom et Prénom" />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <input name="email" id="email" type="email" className="form-control" placeholder="Adresse E-mail" required />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <input name="téléphone" id="téléphone" type="text" className="form-control" placeholder="Numéro de Téléphone" required />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <input name="sujet" id="sujet" type="text" className="form-control" placeholder="Sujet" required />
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="form-group-2 mb-4">
                    <textarea name="message" id="message" className="form-control" rows="8" placeholder="Message" required></textarea>
                  </div>
                </div>
              </div>

              <div>
                <button className="btn btn-main btn-round-full" type="submit">
                  Envoyer
                </button>
              </div>
            </form>
          </div>
          <div className="col-lg-6 mt-4 mt-lg-0">
            <hr className="line"></hr>
      
      <div id="Map-tile" className="col-lg-6 mt-4 mt-lg-0"></div>
      <div className="section-title text-center">
              <h6 className="text-md mb-2">Adresse</h6>
              </div>
      <a href="https://www.google.com/maps/place/Balagne+Medical+Service,+Campu+longu,+Rte+de+Calenzana,+20260+Calvi/@42.5427367,8.7980827,15z/data=!4m6!3m5!1s0x12d0eae11585ca41:0xdaf978f3a8bddf45!8m2!3d42.5427367!4d8.7980827!16s%2Fg%2F11c1w0rbq7">
      <div id="map-tile" className="col-md-6 col-sm-12">
    <iframe
      title="Map-tile"
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11758.36503098528!2d8.7980827!3d42.5427367!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12d0eae11585ca41%3A0xdaf978f3a8bddf45!2sBalagne%20Medical%20Service!5e0!3m2!1sfr!2sfr!4v1708172286731!5m2!1sfr!2sfr"
      width="100%"
      height="250"
      style={{ border: 0 }}
      allowFullScreen
    ></iframe>
    <span>Carte</span>
    </div>
    <div className="section-adress text-center">
    <p className="text-md mb-2">Campu longu, route de Calenzana, 20260, Calvi, Corse, France</p>
    </div>
</a>
       
</div>

</div>
      </div>
</section>
  
  );
}

export default SubmitForm;
