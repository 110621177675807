import React from "react";
import Navbar from "../Components/Navbar";
import Professionnel from "../Components/Professionnel";
import Footerbis from "../Components/Footerbis";

function Professionnels() {
  return (
    <div className="professionnel-section">
        <Navbar />
        <Professionnel />
      <Footerbis />
    </div>
  );
}

export default Professionnels;
